import React from "react"
import Navigation from "../components/navigationModule"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Link} from "gatsby"
import Footer from "../components/footer"
import Styles from "../components/pagenotfound.module.css"


const NotFoundPage = () => (
<>
    <SEO title="404: Not found" />
    <Navigation/>
    <Layout>
    <br/>     <br/>     <br/> 

    <div className="row">   
        <div className="col-md-4 col-sm-12 col-12">
            <div className={Styles.notfoundpage}>
                
            </div>
        </div>

       <div className="col-md-8 col-sm-12 col-12">
                <div className={Styles.notfoundpage_content}>
                    <h1>Oops!</h1>
                    <h3>We can't seem to find the page you are looking for. Here are some helpful links instead</h3>
                    
                    <ul>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                        <Link to="/about">About</Link>
                        </li>
                        <li>
                        <Link to="/services">Services</Link>
                        </li>
                        <li>
                        <Link to="/projects">Projects</Link>
                        </li>
                        <li>
                        <Link to="/contact">Contact</Link>
                        </li>
                    </ul>
                </div>
           

       </div>

       
    </div>
    

    </Layout>
    <Footer/>
    </>
)

export default NotFoundPage
